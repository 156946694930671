import axios from "axios";
import { axiosInstance } from "../../axios";

const GETAllDepartment = "api/ClientDepartment/GetAll";
const DELETEDepartment = "api/ClientDepartment/Delete?id=";
const POSTDepartment = "api/ClientDepartment/Create";


const getAllDepartment = async function () {
    try {
        const response = await axiosInstance.bhAPIInstance.get(GETAllDepartment);
        return parseList(response);
    } catch (error) {
        return null;
    }
};


const deleteDepartment = async function (id) {
    try {
        const response = await axiosInstance.bhAPIInstance.delete(DELETEDepartment + id);
        return parseList(response);
    } catch (error) {
        return null;
    }
};

const createDepartment = async function (department) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(POSTDepartment, department);
        return response.data;
    } catch (error) {
        debugger;
        console.log(error);
        return null;
    }
};





const parseList = response => {
    if (response.status !== 200) throw Error(response.message);
    if (!response.data) return [];
    let list = response.data;
    if (typeof list !== "object") {
        list = [];
    }
    return list;
};


export const departmentData = {
    getAllDepartment,
    deleteDepartment,
    createDepartment
}
